import React, { useState } from "react";
import { IoIosUndo, IoIosSend } from "react-icons/io";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";
import Select from "react-select";

import Modal from "../../../components/Modals";
import api from "../../../services/api";

const ModalAproved = ({
    actionModal,
    openModal,
    entity,
    setLoadingChangeStatus,
    setLoadingAproved,
    setQuoteStatus,
    setDisabledForm
}) => {
    const [loading, setLoading] = useState(false);
    const [description, setDescription] = useState("");
    const [descriptionError, setDescriptionError] = useState("");
    const options = [
        { value: "vendemmia", label: "Indicado pela Vendemmia" },
        { value: "cliente", label: "Indicado pelo cliente" },
    ];

    return (
        <Modal
            onClose={actionModal}
            size="xs:w-11/12 lg:w-4/12"
            show={openModal}
            title={
                <div className="flex items-center">
                    <h5 className={`mr-6 cursor-pointer text-roxo_oficial font-bold`}>
                        Aprovar cotação {entity?.name}
                    </h5>
                </div>
            }>
            <div className="flex items-center">
                <label htmlFor="description" className="w-40 pr-4">
                    Agente de Carga
                </label>
                <Select
                    options={options}
                    id="agente_carga"
                    name="agente_carga"
                    className="w-full outline-none transform-none"
                    placeholder="Agente de Carga"
                    onChange={item => {
                        setDescription(item?.value);
                    }}
                />
            </div>
            {descriptionError && <p className="text-red w-full text-sm">{descriptionError}</p>}
            <p className="mt-6 text-center">Esta ação é permanente, deseja prosseguir?</p>
            <button
                className="mb-4 ml-2 bg-C4 text-white py-2 px-5 rounded-md float-left mt-10 flex items-center"
                onClick={() => {
                    actionModal();
                    setLoadingChangeStatus(false);
                }}>
                <IoIosUndo size={"1em"} />
                <span className="text-lg ml-1">Voltar</span>
            </button>
            <button
                className="mb-4 bg-roxo_oficial text-white py-2 px-5 rounded-md float-right mt-10 flex items-center"
                onClick={() => {
                    if (description.length === 0) {
                        setDescriptionError("O agente de carga é obrigatório");
                        return;
                    }

                    setLoading(true);

                    api.post(
                        "quote/" + entity?.identifier + "/change-status",
                        {
                            status: "approve",
                            description: description,
                        },
                        {
                            headers: {
                                Authorization: "Bearer " + window.localStorage.getItem("session-token"),
                            },
                        }
                    )
                        .then(response => {
                            setLoadingChangeStatus(false);
                            setLoadingAproved(false);
                            setQuoteStatus({
                                'status': response?.data?.status,
                                'date': response?.data?.closedAtFormatted
                            });
                            toast.success("Cotação " + entity?.name + " aprovada com sucesso");
                            setLoading(false);
                            setDisabledForm(true);
                            actionModal();
                        })
                        .catch(error => {
                            setLoading(false);
                            setLoadingChangeStatus(false);
                            setLoadingAproved(false);
                        });
                }}>
                <IoIosSend size={"1em"} />
                <span className="text-lg ml-1">Enviar {loading && <ClipLoader size="13" color="white" />}</span>
            </button>
        </Modal>
    );
};
export default ModalAproved;
